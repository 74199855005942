<template>
    <b-dropdown :ref="action.name" :variant="action.variant" :class="action.class" :style="action.style" :disabled="disabled"
                :size="action.size ? action.size : 'sm'" :right="action.right" :dropup="action.dropup"
                v-on:hide="hideDropDown">
        <template #button-content>
            <template v-if="action.stickychoice">
                <i class="mr-2" :style="selectedChoice.iconstyle" v-if="selectedChoice.icon">
                    <font-awesome-icon :icon="selectedChoice.icon" :size="selectedChoice.iconsize ? selectedChoice.iconsize : 'sm'"/>
                </i>
                {{ selectedChoice.label }}
            </template>
            <template v-else>
                <font-awesome-icon v-if="action.icon" :icon="action.icon" size="sm" :class="marginClass"/>
                <template v-if="!action.compact">{{ action.label }}</template>
            </template>
            &nbsp;
        </template>
        <template v-if="action.checkbox">
            <b-dropdown-form :class="action.formclass" :style="action.formstyle">
                <template v-for="(choice,choiceIndex) in action.choices">
                    <b-form-checkbox :key="action.name+'-choice-'+choiceIndex" size="sm" name="checkbox" v-model="choice.value"
                                     v-on:change="toggleChoice(choice)">
                        {{ choice.label }}
                    </b-form-checkbox>
                </template>
            </b-dropdown-form>
        </template>
        <template v-else>
            <template v-for="(choice,choiceIndex) in action.choices">
                <template v-if="choice.separator">
                    <b-dropdown-divider :key="action.name+'-choice-'+choiceIndex"></b-dropdown-divider>
                </template>
                <template v-else>
                    <b-dropdown-item-button :key="action.name+'-choice-'+choiceIndex"
                                            v-on:click="selectChoice(choice)">
                        <i class="mr-2" :style="choice.iconstyle" v-if="choice.icon">
                            <font-awesome-icon :icon="choice.icon" :size="choice.iconsize ? choice.iconsize : 'sm'"/>
                        </i>
                        {{ choice.label }}
                    </b-dropdown-item-button>
                </template>
            </template>
        </template>
    </b-dropdown>
</template>

<script>
export default {
    name: "DropDownAction",
    props: {
        action: {type: Object, default: null},
        disabled: {type: Boolean, default: false},
        forwardEvents: {type: Boolean, default: false},
        parameterData: {type: Object, default: null},
    },
    computed: {
        marginClass: function () {
            return (this.action.icon && !this.action.compact ? 'mr-2' : '');
        },
        selectedChoice: function () {
            return this.action.choices.find(choice => choice.value === this.action.selected);
        },
    },
    created() {
        if (this.action.choices) {
            this.action.choices.forEach(choice => {
                if (this.parameterData && (choice.valuefield in this.parameterData)) {
                    choice.value = this.parameterData[choice.valuefield];
                } else if (!('value' in choice)) {
                    if ('default' in choice) {
                        choice.value = choice.default;
                    } else {
                        choice.value = false;
                    }
                }
            });
        }
    },
    methods: {
        toggleChoice(choice) {
            if (this.action.choiceevents) {
                let event = choice.event ? choice.event : this.action.name;
                if (this.forwardEvents) {
                    this.$emit('forward-event', {event: event, data: this.action.choices});
                } else {
                    this.$emit(event, this.action.choices);
                }
            }
            if (this.action.closeonselect || choice.closeonselect) {
                this.$refs[this.action.name].hide(true);
            }
        },
        hideDropDown() {
            if (this.action.hideevents) {
                if (this.forwardEvents) {
                    this.$emit('forward-event', {event: this.action.name, data: this.action.choices});
                } else {
                    this.$emit(this.action.name, this.action.choices);
                }
            }
        },
        selectChoice(choice) {
            this.action.selected = choice.value;
            let event = choice.event ? choice.event : this.action.name;
            if (this.forwardEvents) {
                this.$emit('forward-event', {event: event, data: choice});
            } else {
                this.$emit(event, choice);
            }
        }
    }
}
</script>

<style scoped>
</style>
