<template>
    <div class="input-group input-group-sm" :class="action.inputgroupclass" :style="action.inputgroupstyle" v-if="action.fields">
        <template v-for="(field,fieldIndex) in action.fields">
            <div :key="action.name+'-prepend-label-'+fieldIndex" class="input-group-prepend"
                 v-if="!field.compact && field.label && field.type !=='BOOL'">
                <span class="input-group-text">{{ field.label }}</span>
            </div>
            <template v-if="field.type === 'BOOL'">
                <div :key="action.name+fieldIndex" class="input-group-prepend">
                    <span class="input-group-text">
                        <BoolField class="mr-n2 mb-n1" :field="field" :data="inputData" :state="state" v-on:input="checkIfValidInput"/>
                        <span class="ml-2" v-if="!field.compact && field.label">{{ field.label }}</span>
                    </span>
                </div>
            </template>
            <template v-else-if="field.type === 'DATALIST' || field.type === 'RELATION'">
                <SelectField :key="action.name+'-'+fieldIndex"
                             :field="field" :data="inputData" :state="state" v-on:input="checkIfValidInput"/>
            </template>
            <template v-if="field.type === 'ENUM'">
                <EnumField :key="action.name+'-'+fieldIndex"
                           :field="field" :data="inputData" :state="state" v-on:input="checkIfValidInput"/>
            </template>
            <template v-else-if="field.type === 'NUMERIC'">
                <NumericField :key="action.name+'-'+fieldIndex"
                              :field="field" :data="inputData" :state="state" v-on:input="checkIfValidInput"/>
            </template>
            <template v-else-if="field.type === 'TEXT'">
                <TextField :key="action.name+'-'+fieldIndex"
                           :field="field" :data="inputData" :state="state" v-on:input="checkIfValidInput"/>
            </template>
            <div :key="action.name+'-append-label-'+fieldIndex" class="input-group-append"
                 v-if="field.append">
                <span class="input-group-text">{{ field.append }}</span>
            </div>
        </template>
        <span class="input-group-append">
            <button type="button" :class="actionClass" :style="action.style" :disabled="disabled || !validInput" :title="action.label"
                    v-on:click.stop="clickButtonAction">
                <font-awesome-icon v-if="action.icon" :icon="action.icon" :size="'iconsize' in action ? action.iconsize : 'sm'" :class="marginClass"/>
                <template v-if="!action.compact">{{ action.label }}</template>
            </button>
        </span>
    </div>
    <button type="button" :class="actionClass" :style="action.style" :disabled="disabled" :title="action.label"
            v-on:click.stop="clickButtonAction"
            v-else>
        <font-awesome-icon v-if="action.icon" :icon="action.icon" :size="'iconsize' in action ? action.iconsize : 'sm'" :class="marginClass"/>
        <template v-if="!action.compact">{{ action.label }}</template>
    </button>
</template>

<script>
import BoolField from "@/components/forms/fields/BoolField";
import EnumField from "@/components/forms/fields/EnumField";
import NumericField from "@/components/forms/fields/NumericField";
import SelectField from "@/components/forms/fields/SelectField";
import TextField from "@/components/forms/fields/TextField";

export default {
    name: "ButtonAction",
    props: {
        action: {type: Object, default: null},
        disabled: {type: Boolean, default: false},
        forwardEvents: {type: Boolean, default: false},
        parameterData: {type: Object, default: null},
    },
    components: {
        BoolField,
        EnumField,
        NumericField,
        SelectField,
        TextField,
    },
    data() {
        return {
            validInput: true,
            inputData: {},
            state: {
                disabled: false,
            }
        }
    },
    computed: {
        actionClass: function () {
            return 'btn btn-sm ' + this.action.class;
        },
        marginClass: function () {
            return (this.action.icon && !this.action.compact ? 'mr-2' : '');
        },
    },
    created() {
        if (this.action.fields) {
            this.inputData = this.parameterData ? this.parameterData : {};
            this.action.fields.forEach(field => {
                if (!(field.name in this.inputData)) {
                    switch (field.type) {
                        case 'BOOL':
                            if ('value' in field) {
                                this.inputData[field.name] = (!field.value || field.value === '0' || field.value === 'false') ? '0' : '1';
                            } else {
                                this.inputData[field.name] = (!field.default || field.default === '0' || field.default === 'false') ? '0' : '1';
                            }
                            break;
                        default:
                            this.inputData[field.name] = ('value' in field ? field.value : ('default' in field ? field.default : null));
                            break;
                    }
                }
            });
            this.checkIfValidInput();
        }
    },
    methods: {
        checkIfValidInput: function () {
            if (this.action.fields) {
                for (const field of this.action.fields) {
                    if (field.required) {
                        switch (field.type) {
                            case 'BOOL':
                            case 'NUMERIC':
                                if (this.inputData[field.name] == null || this.inputData[field.name] < field.min || this.inputData[field.name] > field.max) {
                                    this.validInput = false;
                                    return;
                                }
                                break;
                            case 'RELATION':
                                if (!this.inputData[field.name] || !this.inputData[field.name]['id']) {
                                    this.validInput = false;
                                    return;
                                }
                                break;
                            case 'TRANSLATABLE':
                                if (!this.inputData[field.name] || !this.inputData[field.name]['translation']) {
                                    this.validInput = false;
                                    return;
                                }
                                break;
                            default:
                                if (!this.inputData[field.name]) {
                                    this.validInput = false;
                                    return;
                                }
                                break;
                        }
                    }
                }
                this.validInput = true;
            }
        },
        clickButtonAction() {
            let data = {};
            if (this.action.fields) {
                for (const field of this.action.fields) {
                    field.value = this.inputData[field.name];
                    if (this.inputData[field.name] != null && this.inputData[field.name] !== "") {
                        switch (field.type) {
                            case 'RELATION':
                                data[field.name] = this.inputData[field.name]['id'];
                                break;
                            case 'TRANSLATABLE':
                                if (this.inputData[field.name]['translation']) {
                                    data[field.name] = this.inputData[field.name];
                                    data[field.translations] = this.inputData[field.translations];
                                }
                                break;
                            default:
                                data[field.name] = this.inputData[field.name];
                                break;
                        }
                    }
                }
            }
            if (this.forwardEvents) {
                this.$emit('forward-event', {
                    event: this.action.name,
                    data: data
                });
            } else {
                this.$emit(this.action.name, data);
            }
        },
    }
}
</script>

<style scoped>
</style>
